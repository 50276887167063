<template>
  <v-card>
    <v-card color="primary" min-height="10" flat tile class="pa-1">
      <v-card-actions class="white--text">
        <v-icon class="mr-2" color="white">mdi-form-select</v-icon>
        {{ isEditing ? "Formulario de edición" : "Formulario de creación" }}
        <v-spacer />
        <v-icon
          large
          @click="closePanel"
          class="mr-1"
          id="btn-close"
          color="white"
          >mdi-close-circle</v-icon
        >
      </v-card-actions>
    </v-card>

    <div class="px-8 py-4">
      <v-card-text class="px-6">
        <v-form v-if="formObject" v-model="valid">
          <v-row align="stretch" justify="start">
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-for="(header, i) in headers"
              :key="i"
            >
              <v-text-field
                :id="`input-${header.value}`"
                v-model="formObject[header.value]"
                type="text"
                :label="header.text"
                v-if="header.type === 'string'"
                :rules="[requiredCheck(formObject[header.value], header)]"
                outlined
              />
              <v-textarea
                :id="`input-${header.value}`"
                v-model="formObject[header.value]"
                type="text"
                height="20"
                :label="header.text"
                :rules="[requiredCheck(formObject[header.value], header)]"
                v-if="header.type === 'long_string'"
                outlined
              />
              <v-text-field
                :id="`input-${header.value}`"
                v-model="formObject[header.value]"
                type="number"
                :label="header.text"
                v-if="header.type === 'number'"
                :rules="[requiredCheck(formObject[header.value], header)]"
                outlined
              />
              <v-combobox
                :id="`input-${header.value}`"
                v-model="formObject[header.value]"
                :items="header.items"
                :label="header.text"
                :return-object="false"
                :multiple="header.type === 'array'"
                :rules="[requiredCheck(formObject[header.value], header)]"
                outlined
                v-if="header.type === 'array' || header.type === 'object'"
              />
              <v-checkbox
                :id="`input-${header.value}`"
                v-model="formObject[header.value]"
                color="primary"
                :label="
                  `${header.text}: ${formObject[header.value] ? 'Si' : 'No'}`
                "
                v-if="
                  header.type === 'boolean' &&
                    header.value.toLowerCase() !== 'status' &&
                    header.value.toLowerCase() !== 'rulegroupsystem'
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-2 pb-2">
        <v-spacer />
        <v-btn @click="closePanel" class="mr-2" depressed color="transparent"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          @click="isEditing ? updateObject() : saveObject()"
          :disabled="!valid"
        >
          {{ isEditing ? "Actualizar" : "Crear" }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
/**
 * Group form
 * @author Cristian Molina
 */
import baseForm from "@/shared/utils/Form";

export default {
  name: "data-form",
  mixins: [baseForm],
};
</script>

<style></style>
